import { render, staticRenderFns } from "./index.vue?vue&type=template&id=462342ab&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrganismsHeroGeneric: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/organisms/hero-generic/hero-generic.vue').default})
