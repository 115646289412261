
import { commonProps } from '~/utils/prismic';
import { LAYOUT_ACTION_FIXED_BUTTON } from '~/store/layout';

export default {
  props: commonProps,
  mounted() {
    this.setFixedButton(this.slice);
  },
  methods: {
    setFixedButton(slice) {
      const { hasFixedButton, cta } = slice.data;
      const ctaLabel = cta?.label;
      const storeValue = hasFixedButton
        ? {
            cta,
            label: ctaLabel,
            alwaysVisible: false,
          }
        : null;
      this.$store.dispatch(`layout/${LAYOUT_ACTION_FIXED_BUTTON}`, storeValue);
    },
  },
};
